<template>
  <div class="card mb-3">
    <h5 class="card-header" v-if="completed">{{$t("description-of-the-project")}} - <b style="color: blue;">{{$t('completed')}}</b></h5>
    <h5 class="card-header" v-else>{{$t("description-of-the-project")}} - <b style="color: red;">{{$t('not-completed')}}</b></h5>
    <div class="card-body">
        <div class="card row">
            <div class="col-lg-12 form-group card-header">
                <label for="title"><b>{{$t("project-title")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p>{{detail.title}}</p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                    <label for="thematic_area"><b>{{$t("research-area-field-branch")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p>{{$t(detail.thematicArea)}}</p>
            </div>
        </div>

        <div class="card row">
            <div class="col-lg-12 form-group card-header">
                <label for="key_words"><b>{{$t("key-words-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p>{{detail.keyWords}}</p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="rationale"><b>{{$t("rationale-and-background-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.rationale"></p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("overall-objective-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.objective"></p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("solution-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.solution"></p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("results-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.results"></p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("benefits-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.benefits"></p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("scalability-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.scalability"></p>
            </div>
        </div>

        <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("literature-references")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.references"></p>
            </div>
        </div>

        <!-- <div class="card row my-3">
            <div class="col-lg-12 form-group card-header">
                <label for="objective"><b>{{$t("mitigation-text")}}</b></label>
            </div>
            <div class="col-lg-12 form-group card-body">
                <p v-html="detail.mitigation"></p>
            </div>
        </div> -->
            
    </div>
  </div>
</template>

<script>
export default {
    components : {},
    data(){
        return {
            detail : {},
            completed : ''
        }
    },

    methods : {
         
        getDetail(){
            this.$http.get(`/project_infos`)
            .then(response => {
                 if(response.data.message){
                    this.detail = response.data.message;
                    localStorage.setItem('project-detail-completed', true)
                    this.completed = localStorage.getItem('project-detail-completed')
                }
                else{
                    localStorage.removeItem('project-detail-completed')
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },

    created(){

        this.getDetail()
    }
}
</script>

<style lang="css">
/* @import 'quill/dist/quill.core.css' */
</style>