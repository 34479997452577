<template>
  <div class="card mb-3" v-if="members.length > 0">
    <h5 class="card-header">{{$t("co-researchers-information")}} - <b v-if="members.length > 0" style="color: blue;">{{$t('completed')}}</b></h5>
        <div class="card-body">
            <div class="card mb-3" v-for="(member, m) in members" :key="`member-${m}`">
                <div class="card-header">
                    <h6>{{$t("co-researcher-no", {number : `# ${m+1}`})}}</h6>
                </div>
                <div class="card-body">
                    <table class="table">
                        <tr>
                            <td>{{$t('name')}}</td>
                            <td><b>{{`${$t(member.prefix)} ${member.surname} ${member.names}`}}</b></td>
                        </tr>
                        <tr>
                            <td>{{$t('email')}}</td>
                            <td><b>{{member.email}}</b></td>
                        </tr>
                       <tr>
                            <td>{{$t('phone')}}</td>
                            <td><b>{{member.phone}}</b></td>
                        </tr>
                        <tr>
                            <td>{{$t('address')}}</td>
                            <td><b>{{member.address}}</b></td>
                        </tr>
                         <tr>
                            <td>{{$t('centre')}}</td>
                            <td><b>{{member.centre}}</b></td>
                        </tr>
                    </table>
                </div>
                
            </div>
            
            
        </div>
  </div>
</template>

<script>

export default {
    components : {
        
    },
    data(){
        return {
            members : []
        }
    },
    methods : {
      getMembers(){
        this.$http.get(`/member_infos`)
            .then(response => {
                if(response.data.message){
                    this.members = response.data.message
                }
            }).catch(e => {
                console.log(e)
            })
        },

    },
    created(){
        this.getMembers();
    }
}
</script>

<style>

</style>