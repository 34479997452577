<template>
  <div class="card mb-3">
    <h5 class="card-header" v-if="completed">{{$t("lead-applicants-information")}} - <b style="color: blue;">{{$t('completed')}}</b></h5>
    <h5 class="card-header" v-else>{{$t("lead-applicants-information")}} - <b style="color: red;">{{$t('not-completed')}}</b></h5>
    <div class="card-body">
        <table class="table">
            <tr>
                <td>{{$t("name")}}</td>
                <td v-if="completed"><b>{{`${$t(leader.prefix)} ${leader.surname} ${leader.names}`}}</b></td>
            </tr>

            <tr>
                <td>{{$t('email')}}</td>
                <td v-if="completed"><b>{{leader.email}}</b></td>
            </tr>
            <tr>
                <td>{{$t('phone')}}</td>
                <td v-if="completed"><b>{{leader.phone}}</b></td>
            </tr>
            <tr>
                <td>{{$t('address')}}</td>
                <td v-if="completed"><b>{{leader.address}}</b></td>
            </tr>
            <tr>
                <td>{{$t('country')}}</td>
                <td v-if="completed"><b>{{leader.country}}</b></td>
            </tr>

             <tr>
                <td>{{$t('centre')}}</td>
                <td v-if="completed"><b>{{leader.centre}}</b></td>
            </tr>

        </table>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            leader : {},
            completed :  ''
        }
    },
    methods : {
        getLeader(){
            this.$http.get(`/leader_infos`)
            .then(response => {
                this.leader = response.data.message
               if(this.leader){
                    localStorage.setItem('leader-info-completed', true)
                    this.completed = localStorage.getItem('leader-info-completed')
                }
                else{
                    localStorage.removeItem('leader-info-completed')
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    created(){
        this.getLeader();
        
    }
}
</script>

<style>

</style>