<template>
  <div class="card mb-3" v-if="organizations.length > 0">
    <h5 class="card-header">{{$t("details-of-other-organizations-involved-in-the-research")}} - <b v-if="organizations.length > 0" style="color: blue;">{{$t('completed')}}</b></h5>
        <div class="card-body">
            <div class="card mb-3" v-for="(organization, o) in organizations" :key="`organization-${o}`">
                <div class="card-header">
                    <h6>{{$t("organization-number", {number : `# ${o+1}`})}}</h6>
                </div>
                <div class="card-body">
                    <table class="table">
                        <tr>
                            <td>{{$t('organization')}}</td>
                            <td><b>{{organization.name}}</b></td>
                        </tr>
                        <tr>
                            <td>{{$t('permanent-address')}}</td>
                            <td><b>{{organization.address}}</b></td>
                        </tr>
                       <tr>
                            <td>{{$t('website')}}</td>
                            <td><b>{{organization.website}}</b></td>
                        </tr>
                    </table>
                    <div class="card mb-3">
                        <div class="card-header"> <h6>{{$t("contact-person-detail")}}</h6></div>
                            <div class="card-body">
                                 <table class="table">
                                    <tr>
                                        <td>{{$t('name')}}</td>
                                        <td><b>{{`${$t(organization.contactPrefix)} ${organization.contactSurname} ${organization.contactNames}`}}</b></td>
                                    </tr>
                                    <tr>
                                        <td>{{$t('email')}}</td>
                                        <td><b>{{organization.contactEmail}}</b></td>
                                    </tr>
                                    <tr>
                                        <td>{{$t('phone')}}</td>
                                        <td><b>{{organization.contactPhone}}</b></td>
                                    </tr>
                                </table>
                            </div>
                    </div>
                </div>
                
            </div>
            
            
        </div>
  </div>
</template>

<script>

export default {
    components : {
        
    },
    data(){
        return {
            organizations : []
        }
    },
    methods : {
      getOrganizations(){
        this.$http.get(`/organizations`)
            .then(response => {
                if(response.data.message){
                    this.organizations = response.data.message
                }
            }).catch(e => {
                console.log(e)
            })
        },

    },
    created(){
        this.getOrganizations();
    }
}
</script>

<style>

</style>