<template>
     <div class="card mb-3">
        <h5 class="card-header" v-if="completed">{{$t("ipr-and-obligations")}} - <b style="color: blue;">{{$t('completed')}}</b></h5>
        <h5 class="card-header" v-else>{{$t("ipr-and-obligations")}} - <b style="color: red;">{{$t('not-completed')}}</b></h5>
         <div class="card-body">
            <div class="card row">
                <div class="col-lg-12 form-group card-header">
                    <label for="title"><b>{{$t("collaborations-and-partnership")}}</b></label>
                </div>
                <div class="col-lg-12 form-group card-body">
                    <p v-html="ipr.partnership"></p>
                </div>
            </div>

            <div class="card row my-3">
                <div class="col-lg-12 form-group card-header">
                        <label for="thematic_area"><b>{{$t("ownership-structure-text")}}</b></label>
                </div>
                <div class="col-lg-12 form-group card-body">
                    <p v-html="ipr.ownership"></p>
                </div>
            </div>   
        </div>
     </div>
</template>

<script>

export default {
    components : {
       
    },

    data(){
        return {
            ipr : {},
            completed : ''
        }
    },
    methods : {
        getIPR(){
            this.$http.get(`/iprs`)
            .then(response => {
                if(response.data.message){
                    this.ipr = response.data.message;
                    localStorage.setItem('ipr-completed', true)
                    this.completed = localStorage.getItem('ipr-completed')
                }
                else{
                    localStorage.removeItem('ipr-completed')
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    created(){
        this.getIPR();
    }
}
</script>

<style>

</style>