<template>
     <div class="card mb-3">
        <h5 class="card-header"  v-if="completed">{{$t("other-relevant-information")}} - <b style="color: blue;">{{$t('completed')}}</b></h5>
        <h5 class="card-header"  v-else>{{$t("other-relevant-information")}} - <b style="color: red;">{{$t('not-completed')}}</b></h5>
        <div class="card-body">
            <div class="card row">
                <div class="col-lg-12 form-group card-header">
                    <label for="title"><b>{{$t("annexes-text")}}</b></label>
                </div>
                <div class="col-lg-12 form-group card-body">
                    <p v-html="other.annexes"></p>
                </div>
            </div>

            <div class="card row my-3">
                <div class="col-lg-12 form-group card-header">
                        <label for="thematic_area"><b>{{$t("url-text")}}</b></label>
                </div>
                <div class="col-lg-12 form-group card-body">
                    <p>{{other.url}}</p>
                </div>
            </div>   
        </div>
     </div>
</template>

<script>

export default {
    components : {
        
    },

    data(){
        return {
            other : {},
            completed : ''
        }
    },
    methods : {
        getOtherInfo(){
            this.$http.get(`/other_infos`)
            .then(response => {
                if(response.data.message){
                    this.other = response.data.message;
                    localStorage.setItem('other-completed', true)
                    this.completed = localStorage.getItem('other-completed')
                }
                else{
                    localStorage.removeItem('other-completed')
                }
            }).catch(e => {
                console.log(e)
            })
        }
    },
    created(){
        this.getOtherInfo();
    }
}
</script>

<style>

</style>