<template>
 <div>
       <div class="breadcrumbs" data-aos="fade-in">
            <div class="container">
                <h2>{{$t("review-and-submit")}}</h2>
            </div>
        </div>
        <section class="contact">
            <div class="container col-md-12" data-aos="fade-up">
                <div class="d-flex justify-content-center row mt-5">
                    <div class="col-lg-12 mt-5 mt-lg-0">
                        <leader-information />

                        <team-members />

                        <organizations />

                        <project-detail />

                        <IPR />

                        <others />
                    </div>
                    <div class="d-flex justify-content-center" >
                        <p style="color:red">{{$t('cant-edit-after-submitting')}}</p>
                    </div>
                    <div class="d-flex justify-content-center register-form">
                        
                        <button class="get-started-btn" @click.prevent="check" type="submit">{{$t("submit-c")}}</button>
                    </div>
                </div>
            </div>
        </section>
 </div>
</template>

<script>
import Organizations from '../components/review/Organizations.vue'
import TeamMembers from '../components/review/TeamMembers.vue'
import LeaderInformation from '../components/review/LeaderInformation.vue'
import ProjectDetail from '../components/review/ProjectDetail.vue'
import IPR from '../components/review/IPR.vue'
import Others from '../components/review/Others.vue';
import {toast} from  "../utils/alert.util";

export default {
    components : {
        LeaderInformation,
        TeamMembers,
        Organizations,
        ProjectDetail,
        IPR,
        Others
    },
    methods : {
        check(){
           if(localStorage.getItem("leader-info-completed")){
            if(localStorage.getItem("project-detail-completed")){
                if(localStorage.getItem("ipr-completed")){
                    if(localStorage.getItem("other-completed")){
                        const loading = this.$vs.loading({
                            color : "#47b2e4",
                            type : "circles",
                            text : this.$t("processing-please-wait")
                        });
                        this.$http.post('/complete')
                        .then(() => {
                                toast.show({
                                icon : "success",
                                title : this.$t("submitted-successfully"),
                            });
                            this.$auth.logout();
                            loading.close();
                        }).catch(e => {
                            loading.close();
                            console.log(e);
                            toast.show({
                                icon : "error",
                                title : this.$t("something-went-wrong", {code : e.response.status})
                            });
                        })
                    }
                    else{
                        toast.show({
                            icon : "error",
                            title : this.$t('fields-incorrectly-filled')
                        });
                    }
                }
                else{
                    toast.show({
                        icon : "error",
                        title : this.$t('fields-incorrectly-filled')
                    });
                }
            }
            else{
                toast.show({
                    icon : "error",
                    title : this.$t('fields-incorrectly-filled')
                });
            }
        }
        else{
            toast.show({
                icon : "error",
                title : this.$t('fields-incorrectly-filled')
            });
        }
    }
    },
    created(){
    }

}
</script>

